import React, { ReactElement, ReactNode, useEffect, useReducer } from 'react';
import * as yup from 'yup';
import {
  ColumnLayout,
  Flash,
  Form,
  FormField,
  FormSection,
  Select,
  Input,
  Multiselect,
  DatePicker,
  TimeInput,
  Textarea,
  Checkbox,
  TokenGroup,
  Button,
} from '@amzn/awsui-components-react/polaris';
import I18n from '../../setupI18n';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { HelpInfoLink } from '../HelpContentRouter';
import { PROJECT_CREATE_MAIN_HELP, PROJECT_TEMPLATE_CREATE_MAIN_HELP } from './projectHelpContent';
import {
  BusinessUnit,
  Client,
  CostCenter,
  Language,
  MediaType,
  Owner,
  Project,
  ProjectStatus,
  ProjectTemplate,
  Subject,
  WorkflowStep,
} from '../types/commonTypes';
import { FormikProps } from 'formik';
import { DateTime, LocalZone, Zone } from 'luxon';
import { AuthenticatedSession, formatLocaleCode, useSession } from '@amzn/et-console-components';
import { Container } from '@amzn/et-polaris-utils';
import { displayTimestamp } from '../../shared/displayTimestamp';
import { coalesceSummaryValue, WizardStepWithSummary } from '../components/WizardWithSummary';
import { T } from '../components/T';
import { ScoringModel } from '../resourceHooks/useScoringModels';

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
`;

const FormContainer = styled('div')`
  max-width: 800px;
  min-width: 280px;
`;

const DateTimeContainer = styled('div')`
  display: inline-block;
  max-width: 200px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const AdditionalPaddingTop = styled('div')`
  padding-top: 10px;
`;

const TruncatedText = styled('div')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;

const getUserTimezone = (session): Zone | string => {
  return session && 'user' in session && session.user.timezone
    ? session.user.timezone
    : new LocalZone();
};

export interface Props<T extends ProjectCreateAPIStep1> extends FormikProps<T> {
  showProjectTemplates?: boolean;
  showProjectTemplateFields?: boolean;
  showEditFields?: boolean;
  isLoadingProjectTemplates?: boolean;
  isLoadingProject?: boolean;
  isLoadingWorkflowSteps: boolean;
  isLoadingScoringModels: boolean;
  isLoadingMediaTypes: boolean;
  isLoadingSubjects: boolean;
  isLoadingClients: boolean;
  isLoadingOwners: boolean;
  isLoadingBusinessUnits: boolean;
  isLoadingCostCenters: boolean;
  isLoadingActiveLanguages: boolean;
  validating: boolean;
  updateProjectTemplate?: (t?: string | number) => void;
  projectTemplates?: ProjectTemplate[];
  project?: Project | ProjectTemplate;
  workflowSteps: WorkflowStep[];
  workflowStepsById: { [id: number]: WorkflowStep };
  scoringModels: ScoringModel[];
  scoringModelsById: { [id: string]: ScoringModel };
  mediaTypes: MediaType[];
  mediaTypesById: { [id: string]: MediaType };
  subjects: Subject[];
  subjectsById: { [id: string]: Subject };
  clients: Client[];
  clientsById: { [id: number]: Client };
  owners: Owner[];
  ownersById: { [id: number]: Owner };
  businessUnits: BusinessUnit[];
  businessUnitsById: { [id: number]: BusinessUnit };
  costCenters: CostCenter[];
  costCentersById: { [id: number]: CostCenter };
  activeLanguages: Language[];
  activeLanguagesByCode: { [code: string]: Language };
}

export interface ProjectCreateAPIStep1 {
  projectTemplateId?: number;
  projectTemplateName?: string;
  name: string;
  ownerId?: number;
  clientId?: number;
  businessUnitId?: number;
  costCenterId?: number;
  mediaType?: string;
  subject?: string;
  sourceLocale?: string;
  targetLocales?: string[];
  dueDate?: string;
  dueTime?: string;
  dueDateTime?: string;
  status: string;
  note?: string;
  workflowStepIds: number[];
  scoringModelIdsByWorkflowStepId: { [id: number]: string };
  updateDueDateOnExistingJobs?: boolean;
}

interface ReducerState {
  projectTemplateId?: number;
  projectTemplates?: (ProjectTemplate | undefined)[];
  isError: boolean;
  error?: string;
  offset: string;
}

export const schemaStep1 = (isProjectMode: boolean): object =>
  yup.object().shape({
    projectTemplateId: yup.number().nullable(),
    projectTemplateName: isProjectMode
      ? yup.string().nullable()
      : yup.string().required('The project template name is required'),
    name: isProjectMode
      ? yup
          .string()
          .max(255, I18n.t('The project name must be less than 256 characters'))
          .required(I18n.t('The project name is required'))
      : yup.string().nullable(),
    workflowStepIds: yup.array(yup.number()).notRequired(),
    ownerId: yup.number().notRequired(),
    clientId: yup.number().notRequired(),
    businessUnitId: yup.number().notRequired(),
    costCenterId: yup.number().notRequired(),
    mediaType: yup.string().notRequired(),
    subject: yup.string().notRequired(),
    sourceLocale: yup.string().required(I18n.t('The source locale is required')),
    targetLocales: yup
      .array()
      .of(yup.string())
      .required(I18n.t('Choose at least one target locale')),
    status: yup.string(),
    note: yup
      .string()
      .nullable()
      .max(8192, 'The note must be less than 8193 characters'),
    scoringModelIdsByWorkflowStepId: yup.object(),
  });

export const initialFormValuesStep1 = (
  isCreationPage?: boolean,
  currentValue?: Project | ProjectTemplate,
  session?: AuthenticatedSession
): ProjectCreateAPIStep1 => {
  const userTimezone = getUserTimezone(session);
  const dueDateTime =
    currentValue && 'dueDate' in currentValue && currentValue.dueDate && userTimezone
      ? DateTime.fromISO(currentValue.dueDate).setZone(userTimezone)
      : undefined;
  const ownerId = isCreationPage ? session?.user.id : currentValue?.ownerId;
  return {
    projectTemplateName:
      currentValue && 'templateName' in currentValue ? currentValue.templateName : undefined,
    name: currentValue?.name ?? '',
    status: currentValue && 'status' in currentValue ? currentValue.status : 'NEW',
    sourceLocale: currentValue?.sourceLocale ?? undefined,
    targetLocales: currentValue?.targetLocales ?? [],
    dueDate: dueDateTime?.toFormat('yyyy-LL-dd') ?? undefined,
    dueTime: dueDateTime?.toFormat('HH:mm') ?? undefined,
    dueDateTime: dueDateTime ? dueDateTime.toISO() : undefined,
    workflowStepIds: currentValue?.workflowStepIds ?? [],
    note: currentValue?.note ?? undefined,
    ownerId: ownerId,
    clientId: currentValue?.clientId ?? undefined,
    costCenterId: currentValue?.costCenterId ?? undefined,
    businessUnitId: currentValue?.businessUnitId ?? undefined,
    mediaType: currentValue?.mediaType ?? undefined,
    subject: currentValue?.subject ?? undefined,
    scoringModelIdsByWorkflowStepId: currentValue?.scoringModelIdsByWorkflowStepId ?? {},
    updateDueDateOnExistingJobs: currentValue?.['uid'] ? false : undefined, // Don't set this value if the currentValue is undefined or isn't a project
  };
};

const Content = <T extends ProjectCreateAPIStep1>({
  errors,
  values,
  setFieldValue,
  handleBlur,
  handleChange,
  validating,
  updateProjectTemplate,
  isLoadingProjectTemplates,
  isLoadingProject,
  isLoadingScoringModels,
  isLoadingMediaTypes,
  isLoadingSubjects,
  isLoadingClients,
  isLoadingOwners,
  isLoadingBusinessUnits,
  isLoadingCostCenters,
  isLoadingActiveLanguages,
  projectTemplates,
  project,
  workflowSteps,
  workflowStepsById,
  scoringModels,
  mediaTypes,
  subjects,
  clients,
  owners,
  businessUnits,
  costCenters,
  activeLanguages,
  showProjectTemplateFields = false,
  showEditFields = false,
  showProjectTemplates = false,
}: Props<T>): ReactElement => {
  const init = (): ReducerState => {
    return {
      isError: false,
      offset: DateTime.local().toFormat('ZZZZZ'),
    };
  };

  const reducer = (state, action): ReducerState => {
    switch (action.type) {
      case 'offsetChanged':
        return {
          ...state,
          offset: action.offset,
        };
      case 'loadProjectTemplates':
        return {
          ...state,
          projectTemplates: [
            undefined,
            ...action.projectTemplates.sort(function(a, b) {
              const templateName1 = a.templateName.toLowerCase();
              const templateName2 = b.templateName.toLowerCase();
              return templateName1 === templateName2 ? 0 : templateName1 < templateName2 ? -1 : 1;
            }),
          ],
        };
      default:
        return state;
    }
  };

  const [reducerState, dispatch] = useReducer(reducer, null, init);
  const { offset } = reducerState;

  const session = useSession() as AuthenticatedSession;
  const userTimezone = getUserTimezone(session);

  const toDateTime = (date: string | undefined, time: string | undefined): DateTime | undefined => {
    let dateString: string;
    if (!date || date.length === 0) {
      return undefined;
    }
    if (time && time.length > 0) {
      dateString = `${date}T${time}`;
    } else {
      dateString = date;
    }
    return DateTime.fromISO(dateString, {
      zone: userTimezone,
    });
  };

  useEffect(() => {
    dispatch({
      type: 'offsetChanged',
      offset: DateTime.local()
        .setZone(getUserTimezone(session))
        .toFormat("ZZZZZ '(UTC'ZZ')'"),
    });
  }, [session]);

  useEffect(() => {
    if (updateProjectTemplate && values.projectTemplateId) {
      updateProjectTemplate(values.projectTemplateId);
    }
  }, [updateProjectTemplate, values.projectTemplateId]);

  useEffect(() => {
    //This is the initial set up for project templates
    if (projectTemplates && projectTemplates.length > 0) {
      dispatch({
        type: 'loadProjectTemplates',
        projectTemplates: projectTemplates,
      });
    }
  }, [projectTemplates]);

  const handleChangeDate = (e: CustomEvent<DatePicker.ChangeDetail>): void => {
    handleChange(e);

    const dueDate = e.detail.value;
    const dateTime = toDateTime(dueDate, values.dueTime);
    let offset;

    if (dateTime !== undefined) {
      offset = dateTime.toFormat("ZZZZZ '(UTC'ZZ')'");
      setFieldValue('dueDateTime', dateTime.toISO());
    } else {
      offset = '';
      setFieldValue('dueDateTime', '');
    }
    dispatch({ type: 'offsetChanged', offset: offset });
  };

  const handleChangeTime = (e: CustomEvent<TimeInput.ChangeDetail>): void => {
    handleChange(e);

    const dueTime = e.detail.value;
    const dateTime = toDateTime(values.dueDate, dueTime);
    let offset;
    if (dateTime !== undefined) {
      offset = dateTime.toFormat("ZZZZZ '(UTC'ZZ')'");
      setFieldValue('dueDateTime', dateTime.toISO());
    } else {
      offset = '';
      setFieldValue('dueDateTime', '');
    }

    dispatch({ type: 'offsetChanged', offset: offset });
  };

  const handleChangeWorkflowSteps = (e): void => {
    if (!e.target) {
      return;
    }
    const id: number = parseInt(
      e.target.component.__properties.controlId.replace('workflowStep', '')
    );
    if (e.detail.checked && !values.workflowStepIds.includes(id)) {
      setFieldValue('workflowStepIds', [...values.workflowStepIds, id]);
    } else if (values.workflowStepIds.includes(id)) {
      setFieldValue(
        'workflowStepIds',
        values.workflowStepIds.filter(v => v !== id)
      );
    }
  };

  const handleChangeScoringModel = (e): void => {
    if (!e.target) {
      return;
    }
    const workflowStepId: number = parseInt(
      e.target.component.__properties.controlId.replace('workflowStepScoringModel', '')
    );
    setFieldValue('scoringModelIdsByWorkflowStepId', {
      ...values.scoringModelIdsByWorkflowStepId,
      [workflowStepId]: e.detail.selectedId,
    });
  };

  const handleChangeNumber = (e): void => {
    const controlId = e.target.component.__properties.controlId;
    const value = e.detail.selectedId;
    setFieldValue(controlId, value == null ? undefined : parseInt(value));
  };

  const handleChangeTargetLocales = (e): void => {
    const selectedCodes = e.detail.selectedIds;
    if (!showProjectTemplateFields) {
      setFieldValue('targetLocales', [...(project?.targetLocales ?? []), ...selectedCodes]);
    } else {
      setFieldValue('targetLocales', selectedCodes);
    }
  };

  const handleChangeProjectTemplate = (e): void => {
    if (e.detail.selectedId === '') {
      setFieldValue('projectTemplateId', undefined);
    } else {
      setFieldValue('projectTemplateId', parseInt(e.detail.selectedId));
    }
  };

  const isDateEnabled = (date: Date): boolean => {
    const dateTime = toDateTime(date.toISOString(), '') ?? DateTime.local();
    return dateTime >= DateTime.local().minus({ days: 1 });
  };

  const scoringModelsPlusNone: ScoringModel[] = [
    { id: 'External', name: 'None', threshold: 0 },
    ...scoringModels,
  ];
  const clientsPlusNone: Client[] = [{ id: undefined, name: 'None' }, ...clients];
  const ownersPlusNone: Owner[] = showProjectTemplateFields
    ? [{ id: undefined, name: 'None' }, ...owners]
    : owners;
  const businessUnitsPlusNone: Client[] = [{ id: undefined, name: 'None' }, ...businessUnits];
  const costCentersPlusNone: Client[] = [{ id: undefined, name: 'None' }, ...costCenters];
  const mediaTypesPlusNone: MediaType[] = [{ id: undefined, name: 'None' }, ...mediaTypes];
  const subjectsPlusNone: Subject[] = [{ id: undefined, name: 'None' }, ...subjects];
  return (
    <FormContainer>
      <Form
        errorText={
          validating && Object.keys(errors).length !== 0
            ? I18n.t('The form contains errors. Fix them and resubmit.')
            : ''
        }
      >
        <FormSection header={I18n.t('Basic details')}>
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              {showProjectTemplates && (
                <FormField
                  label={I18n.t('Apply template')}
                  errorText={validating && errors['projectTemplateId']}
                >
                  <Select
                    loading={isLoadingProjectTemplates}
                    filteringType="auto"
                    options={(reducerState.projectTemplates ?? []).map(projectTemplate => {
                      return {
                        id: projectTemplate ? projectTemplate.id && '' + projectTemplate.id : '',
                        label: projectTemplate ? projectTemplate.templateName : 'None',
                      };
                    })}
                    selectedId={'' + values.projectTemplateId}
                    onChange={handleChangeProjectTemplate}
                    onBlur={handleBlur}
                    controlId="projectTemplateId"
                    id="projectTemplateId"
                  />
                </FormField>
              )}
              {showProjectTemplateFields && (
                <FormField
                  id="templateNameField"
                  label={I18n.t('Project template name')}
                  errorText={validating && errors['projectTemplateName']}
                >
                  <Input
                    id="projectTemplateName"
                    name="projectTemplateName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.projectTemplateName}
                  />
                </FormField>
              )}
              <FormField
                id="nameField"
                label={I18n.t('Project name')}
                errorText={validating && errors['name']}
              >
                <Input
                  id="name"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </FormField>
              <FormField
                id="sourceLocaleField"
                label={I18n.t('Source locale')}
                description={I18n.t('Language of the original content')}
                errorText={validating && errors['sourceLocale']}
              >
                <Select
                  loading={isLoadingActiveLanguages}
                  filteringType="auto"
                  options={activeLanguages.map(lang => {
                    return {
                      id: lang.code,
                      label: lang.name,
                      labelTag: formatLocaleCode(lang.code),
                      disabled: false,
                    };
                  })}
                  selectedId={values.sourceLocale}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  controlId="sourceLocale"
                  id="sourceLocale"
                  disabled={
                    !showProjectTemplateFields && ((isLoadingProject ?? false) || project != null)
                  }
                />
              </FormField>
              <FormField
                id="targetLocalesField"
                label={project ? I18n.t('Add target locale(s)') : I18n.t('Target locale(s)')}
                description={I18n.t('Languages the content will be translated into')}
                errorText={validating && errors['targetLocales']}
              >
                <Multiselect
                  loading={isLoadingActiveLanguages}
                  filteringType="auto"
                  options={activeLanguages.map(lang => {
                    return {
                      id: lang.code,
                      label: lang.name,
                      labelTag: formatLocaleCode(lang.code),
                      disabled:
                        !showProjectTemplateFields &&
                        project?.targetLocales?.includes(lang.code) === true,
                    };
                  })}
                  checkboxes={true}
                  selectedIds={values.targetLocales}
                  onChange={handleChangeTargetLocales}
                  onBlur={handleBlur}
                  controlId="targetLocales"
                  id="targetLocales"
                />
                <AdditionalPaddingTop>
                  <Button
                    id="clearTargetLocalesButtons"
                    variant={'normal'}
                    formAction={'none'}
                    onClick={(): void =>
                      setFieldValue(
                        'targetLocales',
                        showProjectTemplateFields ? [] : project?.targetLocales ?? []
                      )
                    }
                  >
                    {I18n.t('Clear target locales')}
                  </Button>
                </AdditionalPaddingTop>
              </FormField>
              {project && !showProjectTemplateFields && (
                <FormField label={I18n.t('Current target locale(s)')}>
                  <TokenGroup
                    items={activeLanguages
                      .filter(lang => project?.targetLocales?.includes(lang.code))
                      .map(lang => {
                        return {
                          id: lang.code,
                          label: lang.name,
                          labelTag: formatLocaleCode(lang.code),
                          disabled: true,
                        };
                      })}
                  />
                </FormField>
              )}
              <FormField
                label={I18n.t('Due date')}
                description={I18n.t('Date/time when the project must be completed')}
                hintText={I18n.t('Time zone: %{tz}', { tz: offset })}
                errorText={validating && errors['dueDate']}
              >
                <DateTimeContainer>
                  <DatePicker
                    placeholder="YYYY-MM-DD"
                    todayLabel={I18n.t('Today')}
                    nextMonthLabel={I18n.t('Next month')}
                    previousMonthLabel={I18n.t('Previous month')}
                    value={values.dueDate ?? ''}
                    onChange={handleChangeDate}
                    isDateEnabled={isDateEnabled}
                    name="dueDate"
                    id="dueDate"
                  />
                </DateTimeContainer>
                <DateTimeContainer>
                  <TimeInput
                    placeholder={'hh:mm'}
                    format={'hh:mm'}
                    value={values.dueTime ?? '00:00'}
                    onChange={handleChangeTime}
                    name="dueTime"
                    id="dueTime"
                  />
                </DateTimeContainer>
              </FormField>
              {showEditFields && !showProjectTemplateFields && (
                <FormField>
                  <Checkbox
                    id="updateDueDateOnExistingJobs"
                    name={'updateDueDateOnExistingJobs'}
                    checked={values.updateDueDateOnExistingJobs}
                    onChange={handleChange}
                  >
                    Update all job workflow step due dates
                  </Checkbox>
                </FormField>
              )}
              <FormField
                label={I18n.t('Note')}
                description={I18n.t('Other details or special instructions for this project')}
                errorText={validating && errors['note']}
              >
                <Textarea
                  name="note"
                  id="note"
                  value={values.note}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </FormSection>
        <FormSection header={I18n.t('Workflow')}>
          <FormField
            label={I18n.t('Included workflow steps')}
            description={I18n.t('Choose which workflow steps will be included in this project')}
            hintText={I18n.t(
              'You may add new workflow steps after project creation, but existing ones can not be removed and you can not add steps that are before existing ones'
            )}
            errorText={validating && errors.workflowStepIds}
          >
            {workflowSteps.map(ws => (
              <React.Fragment key={ws.id}>
                <Checkbox
                  label={ws.name}
                  id={`workflowStep${ws.id}`}
                  controlId={`workflowStep${ws.id}`}
                  onChange={handleChangeWorkflowSteps}
                  checked={values.workflowStepIds.includes(ws.id)}
                  disabled={
                    !showProjectTemplateFields &&
                    ws.ord <=
                      Math.max(
                        ...(project?.workflowStepIds?.map(id => workflowStepsById[id]?.ord) ?? [-1])
                      )
                  }
                />
                {['SCORING', 'SCORING_AND_REVISION'].includes(ws.type) &&
                  values.workflowStepIds.includes(ws.id) && (
                    <FormField
                      label={I18n.t('Scoring model')}
                      hintText={I18n.t(
                        'You may select a scoring model, but adding scoring workflow steps is currently not supported'
                      )}
                    >
                      <Select
                        loading={isLoadingScoringModels}
                        id={`workflowStepScoringModel${ws.id}`}
                        controlId={`workflowStepScoringModel${ws.id}`}
                        options={scoringModelsPlusNone.map(sm => {
                          return {
                            id: typeof sm.id == 'string' ? sm.id : `${sm.id.scope}/${sm.id.id}`,
                            label: sm.name,
                          };
                        })}
                        selectedId={values.scoringModelIdsByWorkflowStepId[ws.id] ?? 'External'}
                        onChange={handleChangeScoringModel}
                        onBlur={handleBlur}
                        disabled={
                          (project?.workflowStepIds?.includes(ws.id) ?? false) &&
                          !showProjectTemplateFields
                        }
                      />
                    </FormField>
                  )}
              </React.Fragment>
            ))}
          </FormField>
        </FormSection>
        <FormSection header={I18n.t('Other metadata')}>
          {clients.length > 0 && (
            <FormField label={I18n.t('Client')} errorText={validating && errors.clientId}>
              <Select
                loading={isLoadingClients}
                filteringType="auto"
                options={clientsPlusNone.map(client => {
                  return {
                    id: client.id && '' + client.id,
                    label: client.name,
                  };
                })}
                selectedId={'' + values.clientId}
                onChange={handleChangeNumber}
                onBlur={handleBlur}
                controlId="clientId"
                id="clientId"
              />
            </FormField>
          )}

          {// now allowing setting owner on creates because web API doesn't allow setting owner on template create and
          // the old UI behavior was to not allow setting the owner on project create
          showEditFields && owners.length > 0 && (
            <FormField label={I18n.t('Owner')} errorText={validating && errors.ownerId}>
              <Select
                loading={isLoadingOwners}
                filteringType="auto"
                options={ownersPlusNone.map(owner => {
                  return {
                    id: owner.id && '' + owner.id,
                    label: owner.name,
                  };
                })}
                selectedId={'' + values.ownerId}
                onChange={handleChangeNumber}
                onBlur={handleBlur}
                controlId="ownerId"
                id="ownerId"
              />
            </FormField>
          )}

          {costCenters.length > 0 && (
            <FormField label={I18n.t('Cost center')} errorText={validating && errors.costCenterId}>
              <Select
                loading={isLoadingCostCenters}
                filteringType="auto"
                options={costCentersPlusNone.map(costCenter => {
                  return {
                    id: costCenter.id && '' + costCenter.id,
                    label: costCenter.name,
                  };
                })}
                selectedId={'' + values.costCenterId}
                onChange={handleChangeNumber}
                onBlur={handleBlur}
                controlId="costCenterId"
                id="costCenterId"
              />
            </FormField>
          )}
          {businessUnits.length > 0 && (
            <FormField
              label={I18n.t('Business unit')}
              errorText={validating && errors.businessUnitId}
            >
              <Select
                loading={isLoadingBusinessUnits}
                filteringType="auto"
                options={businessUnitsPlusNone.map(businessUnit => {
                  return {
                    id: businessUnit.id && '' + businessUnit.id,
                    label: businessUnit.name,
                  };
                })}
                selectedId={'' + values.businessUnitId}
                onChange={handleChangeNumber}
                onBlur={handleBlur}
                controlId="businessUnitId"
                id="businessUnitId"
              />
            </FormField>
          )}
          <FormField label={I18n.t('Media type')} errorText={validating && errors.mediaType}>
            <Select
              loading={isLoadingMediaTypes}
              filteringType="auto"
              options={mediaTypesPlusNone.map(mediaType => {
                return {
                  id: mediaType.id && '' + mediaType.id,
                  label: mediaType.name,
                };
              })}
              selectedId={values.mediaType && '' + values.mediaType}
              onChange={handleChange}
              onBlur={handleBlur}
              controlId="mediaType"
              id="mediaType"
            />
          </FormField>
          <FormField label={I18n.t('Subject')} errorText={validating && errors.subject}>
            <Select
              loading={isLoadingSubjects}
              filteringType="auto"
              options={subjectsPlusNone.map(subject => {
                return {
                  id: subject.id && '' + subject.id,
                  label: subject.name,
                };
              })}
              selectedId={values.subject && '' + values.subject}
              onChange={handleChange}
              onBlur={handleBlur}
              controlId="subject"
              id="subject"
            />
          </FormField>
          {(isLoadingProject || project) && !showProjectTemplateFields && (
            <FormField label={I18n.t('Status')} errorText={validating && errors.status}>
              <Select
                filteringType="auto"
                options={Object.values(ProjectStatus).map(status => {
                  return {
                    id: status,
                    label: status, // TODO: Use the display status here
                  };
                })}
                selectedId={values.status}
                onChange={handleChange}
                onBlur={handleBlur}
                controlId="status"
                id="status"
              />
            </FormField>
          )}
        </FormSection>
        {reducerState.isError && (
          <div className={formSpacing}>
            <Flash
              id="validation-error"
              type="error"
              dismissible={true}
              content={I18n.t('Please try again. If the issue persists, contact support.')}
              header={I18n.t('Something went wrong.')}
            />
          </div>
        )}
      </Form>
    </FormContainer>
  );
};

export const ProjectCreateStep1Metadata = <T extends ProjectCreateAPIStep1>(
  props: Props<T>
): WizardStepWithSummary => {
  const title = props.showProjectTemplateFields
    ? I18n.t('Project template details')
    : I18n.t('Project details');
  const description = props.showProjectTemplateFields
    ? I18n.t('Enter project template information.')
    : I18n.t(
        'Enter project information. Choose a project template first if applicable, since it will override most other information'
      );
  const helpID = props.showProjectTemplateFields
    ? PROJECT_TEMPLATE_CREATE_MAIN_HELP
    : PROJECT_CREATE_MAIN_HELP;
  return {
    title: title,
    info: (): ReactElement => <HelpInfoLink helpId={helpID} />,
    description: description,
    content: (): ReactElement => <Content {...props} />,
    summary: ({ currentValue }): ReactNode => {
      const {
        values,
        workflowStepsById,
        mediaTypesById,
        subjectsById,
        clientsById,
        ownersById,
        businessUnitsById,
        costCentersById,
        activeLanguagesByCode,
        projectTemplates,
      } = props;

      const formattedDueDate = values.dueDate
        ? displayTimestamp(
            values.dueDate +
              'T' +
              (values.dueTime && values.dueTime !== '' ? values.dueTime : '00:00')
          )
        : '-';

      const projectTemplate = values.projectTemplateId
        ? projectTemplates?.filter(pt => pt.id === values.projectTemplateId)?.[0]
        : undefined;
      values.workflowStepIds.sort(
        (a, b) => props.workflowStepsById[a]?.ord - props.workflowStepsById[b]?.ord
      );

      const summaryElements = [
        coalesceSummaryValue({
          value: values.projectTemplateId,
          label: I18n.t('Project template'),
          displayValue: projectTemplate?.templateName, // TODO
        }),
        coalesceSummaryValue({
          currentValue: currentValue && 'status' in currentValue ? currentValue.status : 'NEW',
          value: values.status,
          label: I18n.t('Status'),
          displayValue: values.status,
        }),
        coalesceSummaryValue({
          currentValue:
            currentValue && 'dueDate' in currentValue ? currentValue.dueDate : undefined,
          value: values.dueDate,
          label: I18n.t('Due'),
          displayValue: formattedDueDate ?? '-',
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.note,
          label: I18n.t('Note'),
          value: <TruncatedText>{values.note}</TruncatedText>,
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.name,
          label: I18n.t('Name'),
          value: values.name,
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.workflowStepIds,
          value: values.workflowStepIds,
          label: I18n.t('Workflow steps'),
          displayValue:
            values.workflowStepIds.length > 0
              ? values.workflowStepIds.map(wfs => workflowStepsById[wfs]?.name).join(', ')
              : '-',
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.sourceLocale,
          label: I18n.t('Source locale'),
          value: values.sourceLocale,
          displayValue: values.sourceLocale
            ? activeLanguagesByCode[values.sourceLocale]?.name
            : '-',
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.targetLocales,
          label: I18n.t(
            { one: 'Target locale', other: 'Target locales' },
            { count: values.targetLocales && values.targetLocales.length }
          ),
          value: values.targetLocales,
          displayValue:
            values?.targetLocales?.map(l => activeLanguagesByCode[l]?.name).join(', ') ?? '-',
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.clientId,
          label: I18n.t('Client'),
          value: values.clientId,
          displayValue: values.clientId ? clientsById[values.clientId]?.name : '-',
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.ownerId,
          label: I18n.t('Owner'),
          value: values.ownerId,
          displayValue: values.ownerId ? ownersById[values.ownerId]?.name : '-',
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.costCenterId,
          label: I18n.t('Cost center'),
          value: values.costCenterId,
          displayValue: values.costCenterId ? costCentersById[values.costCenterId]?.name : '-',
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.businessUnitId,
          label: I18n.t('Business unit'),
          value: values.businessUnitId,
          displayValue: values.businessUnitId
            ? businessUnitsById[values.businessUnitId]?.name
            : '-',
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.mediaType,
          label: I18n.t('Media type'),
          value: values.mediaType,
          displayValue: values.mediaType ? mediaTypesById[values.mediaType]?.name : '-',
        }),
        coalesceSummaryValue({
          currentValue: currentValue?.subject,
          label: I18n.t('Subject'),
          value: values.subject,
          displayValue: values.subject ? subjectsById[values.subject]?.name : '-',
        }),
      ].filter(t => t) as ReactNode[];

      return (
        <Container title={title} key={title}>
          <ColumnLayout columns={4}>
            <div data-awsui-column-layout-root="true">
              {summaryElements.length > 0 ? (
                summaryElements
              ) : (
                <div>
                  <div>
                    <T>No changes</T>
                  </div>
                </div>
              )}
            </div>
          </ColumnLayout>
        </Container>
      );
    },
  };
};
